import React, { type SyntheticEvent, type MouseEventHandler } from 'react'
import Button from '@mui/joy/Button'
import Box from '@mui/joy/Box'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import FormLabel from '@mui/joy/FormLabel'
import FormControl from '@mui/joy/FormControl'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'
import type { Moment } from 'moment'
import { Wrapper } from '@googlemaps/react-wrapper'

// React-dates picker
import 'react-dates-gte-react-17/initialize'
import { DayPickerRangeController } from 'react-dates-gte-react-17'
import 'react-dates-gte-react-17/lib/css/_datepicker.css'
import '../styles/react-dates-overrides.scss'

import type {
    SearchType,
    TimeHighlight,
} from '../types'
import { ArrowForwardIcon } from './icons'
import TimePicker from './time-picker'
import {
    isInclusivelyAfterDay,
    getDayLimits,
} from '../helpers'
import PlacesAutocomplete from './places-autocomplete'
import { END_DATE, RESERVATION_MIN_HOURS, START_DATE } from '../constants'
import type { SearchSubmissionType } from '../pwa/types'

export default function SearchModal({
    searchModalOpen,
    handleToggleSearchModalOpen,
    currentSearch,
    modalType,
    handleSearchReset,
    handleSelect,
    handleDateChange,
    handleFocusChange,
    garagesMap,
    reservationDays,
    reservationHours,
    selectedCETDateTime,
    currentCETDateTime,
    searchLink,
    setSearch,
    garageDetail,
    home,
    timeHighlight,
} : {
    searchModalOpen: boolean,
    handleToggleSearchModalOpen: () => void,
    currentSearch: SearchType,
    modalType: 'mobile' | 'desktop',
    handleSearchReset: MouseEventHandler<HTMLAnchorElement>,
    handleSelect: (event: SyntheticEvent | null, newValue: string | null, name : string) => void,
    handleDateChange: ({ startDate, endDate, }: {
        startDate: Moment | null,
        endDate: Moment | null,
    }, focusedInput: typeof START_DATE | typeof END_DATE) => void,
    handleFocusChange: (focusedInput: typeof START_DATE | typeof END_DATE) => void,
    garagesMap?: boolean | undefined,
    reservationDays: number,
    reservationHours: number,
    selectedCETDateTime: Moment,
    currentCETDateTime: Moment,
    searchLink?: string,
    setSearch?: (newSearch: SearchSubmissionType) => void,
    garageDetail?: boolean | undefined,
    home?: boolean | undefined,
    timeHighlight: TimeHighlight,
}) {

    const { t, i18n } = useTranslation()
    const userLang = i18n.language

    const {
        maximumStayDays,
        maximumDaysInAdvance,
    } = getDayLimits()

    const isOutsideRange = (day: Moment): boolean => {
        // Check if day is today or later
        if (!(isInclusivelyAfterDay(day, moment()))) return true
        // Check if day isn't over 150 days from today
        if (isInclusivelyAfterDay(day, moment().add(maximumDaysInAdvance + 1, 'days'))) return true

        // endDate cannot be more than 30 nights from startDate
        if (currentSearch.startDate &&
            currentSearch.focusedInput === END_DATE &&
            isInclusivelyAfterDay(day, currentSearch.startDate.clone().add(maximumStayDays + 1, 'days'))
        ) return true

        return false
    }

    const reservationTooShort = reservationDays < 1 && reservationHours < RESERVATION_MIN_HOURS
    const reservationInThePast = selectedCETDateTime < currentCETDateTime

    if (modalType === 'mobile') {
        return (
            <Modal open={searchModalOpen} onClose={() => handleToggleSearchModalOpen()}>
                <ModalDialog
                    variant="outlined"
                    layout="fullscreen"
                    sx={{
                        backgroundColor: '#f7f7f7',
                        padding: '0',
                    }}
                >
                    <ModalClose variant="plain"/>
                    <Box sx={{
                            display: 'flex',
                            color: 'secondary.solidBg',
                            backgroundColor: 'primary.solidBg',
                            p: 2,
                            fontWeight: 500,
                        }}>
                            <Box>{currentSearch.startDate !== null ? moment(currentSearch.startDate).locale(userLang).format('DD. MMM') : '- -. - -. - - - -'}, {currentSearch.startTime ?? '--:--'}</Box>
                            <Box px={2}>
                                <ArrowForwardIcon color="#ffc400"/>
                            </Box>
                            <Box>{currentSearch.endDate !== null ? moment(currentSearch.endDate).locale(userLang).format('DD. MMM') : '- -. - -. - - - -'}, {currentSearch.endTime ?? '--:--'}</Box>
                        </Box>
                    <Box p={2.5}>
                        {!garageDetail &&
                            <FormControl>
                                <FormLabel>{t('search.where')}</FormLabel>
                                <Wrapper
                                    // @ts-ignore: Property 'env' does not exist on type 'ImportMeta'.
                                    apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                                    version="weekly"
                                    libraries={["places", "marker"]}
                                >
                                    <PlacesAutocomplete
                                        location={currentSearch.location}
                                        handleSelect={handleSelect}
                                        autocompleteType="full"
                                    />
                                </Wrapper>
                            </FormControl>
                        }
                        <Box display="flex" pt={!garageDetail ? 2 : 0}>
                            <TimePicker
                                timeType='start'
                                handleSelect={handleSelect}
                                value={currentSearch.startTime}
                                timeHighlight={timeHighlight.startTime}
                            />
                            <Box pr={2} />
                            <TimePicker
                                timeType='end'
                                handleSelect={handleSelect}
                                value={currentSearch.endTime}
                                timeHighlight={timeHighlight.endTime}
                            />
                        </Box>
                    </Box>
                    {/* display="flex" is a fix for Safari height: 100% not working properly in PWA */}
                    <Box flexGrow={1} display="flex">
                        <DayPickerRangeController
                            startDate={currentSearch.startDate} // momentPropTypes.momentObj or null,
                            endDate={currentSearch.endDate} // momentPropTypes.momentObj or null,
                            onDatesChange={e => {
                                if (currentSearch.focusedInput === START_DATE) {
                                    handleDateChange(e, START_DATE)
                                } else {
                                    handleDateChange(e, END_DATE)
                                }
                            }} // PropTypes.func.isRequired,
                            focusedInput={currentSearch.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => handleFocusChange(focusedInput)} // PropTypes.func.isRequired,
                            // Keep to shift to a specific month when startDate selected, happens by default
                            initialVisibleMonth={() => moment()} // PropTypes.func or null,
                            orientation="verticalScrollable"
                            numberOfMonths={7}
                            verticalHeight={300}
                            noNavButtons
                            noBorder
                            renderKeyboardShortcutsButton={() => {}}
                            monthFormat="MMMM YYYY"
                            minimumNights={0}
                            isOutsideRange={day => isOutsideRange(day)}
                            minDate={moment()}
                            maxDate={moment().add(maximumDaysInAdvance + 1, 'days')}
                            daySize={46}
                        />
                    </Box>
                    <Box sx={{
                        fontSize: '0.875rem',
                        textAlign: 'center',
                        padding: '0.5rem',
                    }}>
                        {!currentSearch.startDate ? t('search.selectStart') :
                            !currentSearch.endDate ? 
                                <Box color="red">  
                                    {t('search.selectEnd')}
                                </Box>
                                : 
                                (reservationDays || reservationHours) ?
                                    <Box color={reservationTooShort ? 'red' : 'inherit'}>
                                        {t('search.reservationLength')+':'}
                                        <span>{reservationDays ? t('search.days', {count: reservationDays}) : ''}</span> 
                                        <span>{reservationHours ? t('search.hours', {count: reservationHours}) : ''}</span>
                                    </Box> 
                                    :
                                    <Box color='red'>{t('search.reservationLength')+': '+t('search.hours', {count: 0})}</Box>
                        }
                    </Box>
                    {reservationInThePast &&
                        <Box sx={{
                            fontSize: '0.875rem',
                            textAlign: 'center',
                            padding: '0rem 0.5rem 0.5rem',
                        }}>
                            <Box color='red'>{t('search.startInThePast')}</Box>
                        </Box>
                    }
                    {reservationTooShort &&
                        <Box sx={{
                            fontSize: '0.875rem',
                            textAlign: 'center',
                            padding: '0rem 0.5rem 0.5rem',
                        }}>
                            <Box color='red'>{t('search.reservationTooShort')}</Box>
                        </Box>
                    }
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem 1.25rem',
                        borderTop: '1px solid #E0E0E0'
                    }}>
                        <Button
                            variant="plain"
                            onClick={handleSearchReset}
                        >
                            {t('search.reset')}
                        </Button>
                        <Button
                            variant="solid"
                            component={searchLink ? 'a' : 'button'}
                            href={searchLink ?? undefined}
                            onClick={setSearch ? () => setSearch({
                                startDate: currentSearch.startDate,
                                startTime: currentSearch.startTime,
                                endDate: currentSearch.endDate,
                                endTime: currentSearch.endTime,
                                location: currentSearch.location,
                            }) : undefined}
                            sx={{
                                // imitate disabled for link
                                ...(!currentSearch.startDate ||
                                    !currentSearch.endDate ||
                                    reservationTooShort ||
                                    reservationInThePast) ? {
                                    pointerEvents: 'none',
                                    cursor: 'default',
                                    color: '#fff',
                                    backgroundColor: '#828282',
                                } : {},
                                // legacy application stylesheet override
                                '&:hover': {
                                    color: 'secondary.solidBg',
                                }
                            }}
                        >
                            {garageDetail ? t('search.edit') : t('search.search')}
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        )
    }

    return (
        <Modal open={searchModalOpen} onClose={() => handleToggleSearchModalOpen()}>
            <ModalDialog
                variant="outlined"
                sx={{
                    backgroundColor: '#f7f7f7',
                    padding: '0',
                    borderRadius: '0.25rem',
                    border: 'none',
                    overflow: 'hidden',
                    maxWidth: '100vw',
                    ...garagesMap ? {
                        top: '442px',
                    } : {},
                }}
            >
                {home &&
                    <ModalClose variant="plain"/>
                }
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'primary.solidBg',
                    p: home ? '1rem 1.25rem 0.75rem' : '0.75rem',
                }}>
                    <Box sx={{
                        fontSize: '0.825rem',
                        textTransform: 'uppercase',
                        fontWeight: 600,
                        color: 'white',
                        marginBottom: '0.25rem',
                    }}>
                        {t('search.reservationDate')}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        color: 'secondary.solidBg',
                        justifyContent: 'start',
                        fontWeight: 500,
                        fontSize: '1.125rem',
                    }}>
                        <Box>{currentSearch.startDate !== null ?
                            <Box display="inline">
                                <Box display="inline" textTransform="capitalize">
                                    {moment(currentSearch.startDate).format('dddd, ')}
                                </Box>
                                {moment(currentSearch.startDate).format('D. MMMM')}
                            </Box> :
                            '- -. - -. - - - -'}, {currentSearch.startTime ?? '--:--'}
                        </Box>
                        <Box px={2}>
                            <ArrowForwardIcon color="#ffc400"/>
                        </Box>
                        <Box>{currentSearch.endDate !== null ?
                            <Box display="inline">
                                <Box display="inline" textTransform="capitalize">
                                    {moment(currentSearch.endDate).format('dddd, ')}
                                </Box>
                                {moment(currentSearch.endDate).format('D. MMMM')}
                            </Box> :
                            '- -. - -. - - - -'}, {currentSearch.endTime ?? '--:--'}
                        </Box>
                    </Box>
                </Box>
                {!garageDetail &&
                    <Box pt={2.5} pb={1.5} px={3}>
                        <FormControl>
                            <FormLabel>{t('search.where')}</FormLabel>
                            <Wrapper
                                // @ts-ignore: Property 'env' does not exist on type 'ImportMeta'.
                                apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                                version="weekly"
                                libraries={["places", "marker"]}
                            >
                                <PlacesAutocomplete
                                    location={currentSearch.location}
                                    handleSelect={handleSelect}
                                    autocompleteType="full"
                                />
                            </Wrapper>
                        </FormControl>
                    </Box>
                }
                <Box display="flex">
                    <Box sx={{
                        padding: '1.5rem',
                        minWidth: 160,
                    }}>
                        <TimePicker
                            timeType='start'
                            handleSelect={handleSelect}
                            value={currentSearch.startTime}
                            timeHighlight={timeHighlight.startTime}
                            desktop
                        />
                    </Box>
                    <Box minHeight={340}>
                        <DayPickerRangeController
                            startDate={currentSearch.startDate} // Required
                            endDate={currentSearch.endDate} // Required
                            onDatesChange={e => {
                                if (currentSearch.focusedInput === START_DATE) {
                                    handleDateChange(e, START_DATE)
                                } else {
                                    handleDateChange(e, END_DATE)
                                }
                            }} // Required
                            focusedInput={currentSearch.focusedInput} // Required
                            onFocusChange={focusedInput => handleFocusChange(focusedInput)} // Required
                            numberOfMonths={2}
                            verticalHeight={400}
                            noBorder
                            renderKeyboardShortcutsButton={() => {}}
                            monthFormat="MMMM YYYY"
                            minimumNights={0}
                            isOutsideRange={day => isOutsideRange(day)}
                            minDate={moment()}
                            maxDate={moment().add(maximumDaysInAdvance + 1, 'days')}
                            transitionDuration={0}
                        />
                    </Box>
                    <Box sx={{
                        padding: '1.5rem',
                        minWidth: 160,
                    }}>
                        <TimePicker
                            timeType='end'
                            handleSelect={handleSelect}
                            value={currentSearch.endTime}
                            timeHighlight={timeHighlight.endTime}
                            desktop
                        />
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0.75rem 1rem',
                    borderTop: '1px solid #E0E0E0'
                }}>
                    <Button
                        variant="plain"
                        onClick={handleSearchReset}
                    >
                        {t('search.reset')}
                    </Button>
                    <Box display="flex">
                        <Box sx={{
                            fontSize: '0.875rem',
                            textAlign: 'right',
                            padding: '0 1rem 0 0.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                            {!currentSearch.startDate ? t('search.selectStart') :
                                !currentSearch.endDate ? 
                                    <Box color="red">  
                                        {t('search.selectEnd')}
                                    </Box>    
                                    : 
                                    (reservationDays || reservationHours) ?
                                        <Box color={reservationTooShort ? 'red' : 'inherit'}>   
                                            {t('search.reservationLength')+':'}
                                            <span>{reservationDays ? t('search.days', {count: reservationDays}) : ''}</span> 
                                            <span>{reservationHours ? t('search.hours', {count: reservationHours}) : ''}</span>
                                        </Box> 
                                        :
                                        <Box color='red'>{t('search.reservationLength')+': '+t('search.hours', {count: 0})}</Box>
                            }
                            {reservationInThePast &&
                                <Box color='red'>{t('search.startInThePast')}</Box>
                            }
                            {reservationTooShort &&
                                <Box color='red'>{t('search.reservationTooShort')}</Box>
                            }
                        </Box>
                        <Button
                            variant="solid"
                            component="a"
                            href={searchLink}
                            sx={{
                                paddingLeft: '1.5rem',
                                paddingRight: '1.5rem',
                                // imitate disabled for link
                                ...(!currentSearch.startDate ||
                                    !currentSearch.endDate ||
                                    reservationTooShort ||
                                    reservationInThePast) ? {
                                    pointerEvents: 'none',
                                    cursor: 'default',
                                    color: '#fff',
                                    backgroundColor: '#828282',
                                } : {},
                                // legacy application stylesheet override
                                '&:hover': {
                                    color: 'secondary.solidBg',
                                }
                            }}
                        >
                            {garageDetail ? t('search.edit') : t('search.search')}
                        </Button>
                    </Box>
                </Box>
            </ModalDialog>
        </Modal>
    )
}